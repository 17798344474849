<template>
    <div class="row my-4 mx-0">
        <div class="col-12 col-md-auto col-lg-12 col-xl-11">
            <div class="bg-f5 border br-8 pr-4 br-50-6">
                <div class="row mx-0 contenedor-info d-middle">
                    <img :src="informationProvider.imagen | helper-storage" alt="" class="img-logo-op wh-126 rounded-circle position-relative">
                    <div class="col py-2">
                        <p class="f-18 f-700">{{ informationProvider.nombre }}</p>
                        <p class="f-14 f-500 my-2">
                            Materiales que maneja: 
                            <span v-for="(data, idx) in informationProvider.tipos_materiales" :key="idx">
                                {{ data }}
                            </span>
                        </p>
                        <div class="row">
                            <div class="col-auto d-middle">
                                <i class="icon-account f-18" />
                                <p class="font-italic f-14 f-500">{{informationProvider.responsable}}</p>
                            </div>
                            <div class="col-auto d-middle">
                                <i class="icon-clipboard-list f-18" />
                                <p v-if="cotizacionesPendientes && cotizacionesPendientes.data" class="font-italic f-14 f-500">{{ cotizacionesPendientes.data.length }} cotizaciones pendientes</p>
                            </div>
                        </div>
                    </div>
                    <div class="col">
                        <slot />
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
export default {
    data() {
        return {
            detallesProveedor: {
                nombre: 'Aceros S.A.S',
                imagen: '/img/logoOp.png',
                responsable: 'Dario Bermudez',
                materiales: [
                    {
                        id: 1,
                        nombre: 'Eléctrico'
                    },
                    {
                        id: 2,
                        nombre: 'Metal'
                    },
                ],
                cotizaciones: 5
            }
        }
    },
    async created () {
        await this.Action_list_cotizaciones();
    },
    computed: {
        ...mapGetters({
            informationProvider: 'users_providers/usersProviders/informationProvider',
            cotizacionesPendientes: 'users_providers/usersProviders/cotizacionesPendientes',
        }),
    },
    methods: {
        ...mapActions({
            Action_list_cotizaciones: 'users_providers/usersProviders/Action_list_cotizaciones_pendientes',
        }),
    },
}
</script>

<style lang="scss" scoped>
.br-50-6{
        border-radius: 50rem 6rem 6rem 50rem !important;
}
.contenedor-info{
    .img-logo-op{
        left: -3px;
        top: -1px;
    }
}
</style>