<template>
    <section class="proveedores-usuarios">
        <navbar-admin  titulo="Proveedores Usuarios" icon="icon-dolly" />
        <info-top>
            <div v-if="Object.entries(informationTop).length" class="text-right">
                <p class="f-14 f-600 my-1">
                    Cotizacion {{ informationTop.referencia }}
                </p>
                <p class="f-14 f-600 my-1">
                   Cantidad materiales: {{ informationTop.cantidad_materiales }}
                </p>
                <p v-if="informationTop.fecha_cotizacion" class="f-14 f-600 my-1">
                    Fecha de cotización:
                    <span class="f-300 f-12">{{ informationTop.fecha_cotizacion | helper-fecha('DD MMM YYYY') }}</span>
                </p>
                <p v-if="informationTop.fecha_vencimiento && routeName(this.$route.name)" class="f-14 f-600 my-1">
                    Fecha de vencimiento:
                    <span class="f-300 f-12">{{ informationTop.fecha_vencimiento | helper-fecha('DD MMM YYYY') }}</span>
                </p>
            </div>
        </info-top>
        <tabs :tabs="tabsProveedoresUsuarios" />
        <router-view />
    </section>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import infoTop from './components/infoTop.vue'
export default {
    components: {
        infoTop,
    },
    data() {
        return {
            tabsProveedoresUsuarios: [
                {
                    titulo:'Cotizaciones pendientes',
                    ruta:'proveedores.usuarios.cotizaciones.pendientes',
                },
                {
                    titulo:'Cotizaciones realizadas',
                    ruta:'proveedores.usuarios.cotizaciones.realizadas',
                },
                {
                    titulo:'Cotizaciones vencidas',
                    ruta:'proveedores.usuarios.cotizaciones.vencidas',
                },
                {
                    titulo:'Información',
                    ruta:'proveedores.usuarios.informacion',
                },
            ],
        }
    },
    async created () {
        await this.Action_get_information_provider(this.user.id);
        this.getSelects(['Action_get_select_monedas'])
    },
    computed: {
        ...mapGetters({
            user: 'auth/user',
            informationTop: 'users_providers/usersProviders/infoTop',
            select_monedas: 'selects/selects/select_monedas',
        }),
    },
    methods: {
        ...mapActions({
            Action_get_information_provider: 'users_providers/usersProviders/Action_get_information_provider',
            getSelects: 'selects/selects/sync'
        }),
        routeName(route) {
            return route == 'proveedores.usuarios.cotizaciones.pendientes'
        }
    }
}
</script>

<style lang="scss" scoped>
.proveedores-usuarios{
    background: white;
}
</style>